@import '../../../styles/variables';

.errorNoWallet {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: $white;
  background-color: $colorErrMsgNoWallet;
  min-height: 32px;
  padding: 8px 12px;
  margin: 20px 0 10px;
  border-radius: 8px;
}
