@import '../../styles/variables';

.errorWrapper {
  width: 100%;
  height: 32px;
  padding: 8px 12px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  background: $red;
  cursor: pointer;
  margin: 0;
}

.underlinedText {
  text-decoration: underline;
}
