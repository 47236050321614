@import '../../styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;

  .header {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    color: $black;
    text-align: left;
    margin: 0 0 12px 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .headerMobile {
    display: none;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    color: $black;
    text-align: left;
    margin: 0 0 12px 0;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__addButton {
    background: $light-gray;
    border-radius: 4px;
    color: $blue;
    margin-top: 24px;
  }

  &__addWalletText {
    max-width: 450;
    font-size: 14px;
    margin: 20px 0 10px;
    color: #000;

    a {
      color: #4c9de8;
    }
  }
}
