@import '../../styles/variables';

.container {
  padding: 16px;
  background: #f6f4f8;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);

  .header {
    width: 100%;
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 12px 0;
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $tablet) {
    order: 3;
    margin: 24px 0 0;
  }
}
