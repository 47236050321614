.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d7eafc;
  padding: 12px 0;

  p {
    margin: 0;
  }

  &.noBorder {
    border-bottom: none;
    padding-bottom: 0;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    color: #1b2125;
  }

  .value {
    font-size: 14px;
    font-weight: 700;
    color: #1b2125;
  }

  @media (max-width: 767px) {
    .label, .value {
      font-size: 12px;
    }
  }
}
