@import '../../styles/variables';

.calculator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  min-width: 503px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);

  .textInfo {
    color: #1b2125;
  }

  .loader {
    width: 19px;
    height: 19px;
    display: block;

    &::after {
      width: 15px;
      height: 15px;
      border-width: 2px;
      border-color: #ffffffff #ffffffff #ffffffff transparent;
    }
  }

  .line {
    width: 100%;
    height: 0;
    border: 1px solid #d7eafc;
    margin: 16px 0;
  }

  .toggleNav {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 8px;

    .navBtn {
      width: 50%;
      min-width: 146px;
      height: 32px;
      padding: 0;
      text-transform: uppercase;
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 12px;
    }
  }

  .buyButton {
    height: 48px;
    margin-top: 16px;
    background: #03b481;
    border: 1px solid currentColor;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border: 1px solid currentColor;
    }

    @media screen and (max-width: $tablet) {
      margin-top: 24px;
    }
  }

  .error {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: $red;
    margin-top: 5px;
  }
}

.combinedInput {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.combinedInputContainer {
  sup {
    display: none;
  }

  label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}

.inputSelect {
  width: 142px;
  position: static !important;
  flex-shrink: 0;
  background-color: #5583ff;
  color: #fff;
  border: none;
  border-radius: 0 8px 8px 0;

  :global {
    .select {
      position: static;
      display: flex;
      align-items: center;
      padding: 12px 0 12px 15px;
      height: 100%;
      border: none;

      .currency-item {
        font-weight: 500;
        font-size: 20px;
        padding-left: 0;
        color: #fff;
      }

      .currency-name {
        display: none;
      }

      .select__caret {
        width: 24px;
        height: 24px;

        path {
          fill: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .currency-item {
      overflow: hidden;
      font-size: 16px;
      font-weight: 500;
      padding-left: 20px;
      padding-right: 30px;
      color: #1b2125;

      .currency-name {
        font-size: 14px;
        color: #adadad;
        line-height: 16px;
        font-weight: 400;
      }
    }

    .select_drop-menu {
      right: 0;
      margin-top: 0;
      border-radius: 8px;
    }

    .img {
      margin-right: 8px;
    }

    .ticker {
      min-width: 80px;
    }

    .search-wrapper {
      display: flex;
      padding: 18px;
      border-bottom: 1px solid #d7eafc;

      &::before {
        content: '';
        flex-shrink: 0;
        display: block;
        margin-top: 3px;
        width: 20px;
        height: 20px;
        -webkit-background-size: contain;
        background-size: contain;
      }

      input {
        padding-left: 10px;
        height: 24px;
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: $mobileL) {
    width: 132px;
  }
}

@media screen and (max-width: $tablet) {
  .calculator {
    min-width: 100%;
    width: 100%;
    padding: 16px;

    .error {
      position: relative;
      transform: none;
      padding: 12px 0 0;
    }
  }
}

.becomePartnerButton {
  display: flex;
  width: 100%;
  padding: 12px 0;
  background-color: #03b481;
  box-shadow: 0px 2px 16px rgba(0, 57, 166, 0.08);
  border-radius: 12px;
  color: #fff;
  font-size: 19px;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.headerConfirm {
  position: absolute;
  top: -64px;
  left: 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.3;
  color: #1b2125;
  background: #f6f4f8;
  z-index: 99999;

  @media screen and (max-width: $tablet) {
    left: 49px;
    top: -46px;
    font-size: 24px;

    span {
      display: none;
    }
  }
}
