@import '../../../styles/variables';

.selector {
  width: 100%;
  height: 40px;
  border: 2px solid rgba(3, 180, 129, 0.5);
  border-radius: 4px;
  margin: 12px 0;
  font-size: 14px;
  appearance: none;
  background-image: url('./dropdown_arrow.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 12px;
  padding: 0 26px 0 12px;

  &:focus {
    outline: none;
  }

  &::after {
    display: block;
    position: absolute;
    right: 5px;
    background: red;
  }

  @media (max-width: $mobileL) {
    background-position: calc(100% - 6px) center;
  }
}
