@import '../../styles/variables';

.checkboxStyles {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 0;
  color: black;
  font-size: 12px;

  &.hasError {
    label {
      color: $red;
    }
  }

  &__square {
    border-color: #4c9de8;
    background-color: #0b76ef;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &__link {
    color: #4c9de8;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: $tablet) {
    margin-top: 12px;
  }
}

.align {
  align-items: flex-start;
}
