/* COLOR VARIABLES */
$black: #1b2125;
$border-color: #cbd7fd;
$white: #fff;
$blue: #4c9de8;
$very-light-blue: #d7eafc;
$very-very-light-blue: #f3f9ff;

$dark-blue: #0034a0;
$gray: #a4a3aa;
$light-gray: #f6f4f8;
$gray-dark: #788995;
$green: #00996d;
$red: #d4322b;
$successColor: #03b481;
$colorErrMsgNoWallet: rgba(255, 57, 30, 0.83);

/* SCREEN VARIABLES */
$laptop: 1023px;
$tablet: 767px;
$mobileL: 424px;
